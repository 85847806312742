.filter {
    width: 100%;
    padding: 10px;
    margin: 0 0 10px 0;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.55);
    backdrop-filter: blur(10px);
    position: sticky;
    top: 85px;
    border-radius: 4px;
    z-index: 120;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);

    p {
        margin: 0 0 4px 0;
        font-size: 20px;
        color: #202020;
    }

    .inputs {
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    .info {
        margin: 5px 0 0 0;
    }
}
