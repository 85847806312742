.imageWrapper {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    .loadingOverlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 100;
        opacity: 0;
        visibility: hidden;
        background-color: #eee;

        transition: .27s all;
    }

    .loadingOverlayActive {
        opacity: 1;
        visibility: visible;

        transition: .27s all;
    }

    img {
        width: 100%;
        height: auto;
    }
}