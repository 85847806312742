.sidebar {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: 8px;
    /*
    position: sticky;
    top: 5px;
    */
    box-sizing: border-box;
    padding: 20px 15px 10px 15px;

    .activeLink {
        background: hsla(0,0%,100%,.22);
        box-sizing: border-box;
        color: #fff;
        transition: 0.1s all;
    }

    a {
        align-items: center;
        background: hsla(0,0%,100%,.02);
        border: 1px solid hsla(0,0%,100%,.065);
        border-radius: 5px;
        color: #fff;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 10px;
        padding: 15px 10px;
        text-decoration: none;
        transition: all .12s;

        img.tenge {
            max-width: 15px;
            height: auto;
            filter: invert(1);
            margin-left: 3px;
        }

        &:hover {
            background: hsla(0,0%,100%,.22);
            box-sizing: border-box;
            color: #fff;
            transition: 0.1s all;
        }

        span.title {
            margin-left: 32px;
            position: absolute;
            margin-top: 2px;
            font-weight: normal;
        }
    }

    p {
        margin: 0 0 0 15px;
        font-weight: 900;
        font-size: 18px;
        color: #000000;
        position: relative;
        top: -2px;
    }
}
