.wrapper {
    width: 100%;
    margin: 15px 0;
}

.groupWrapper {
    width: 100%;
    border: 1px solid rgba(226, 226, 226, 0.3);
    margin-bottom: 15px;
    border-radius: 5px;
    box-sizing: border-box;

    .header {
        background-color: rgb(243, 243, 243);
        box-sizing: border-box;
        padding: 10px;
        border-radius: 5px;
    }

    .content {
        box-sizing: border-box;
        padding: 10px;
        border-radius: 5px;
    }
}

.buttonGroup {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;

    .button {
        width: 100%;
    }
}
