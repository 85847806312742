.managerItem {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 10px;

    > div {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    > section {
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid rgba(0, 0, 0, 0.2);

        svg {
            padding: 5px;
            border-radius: 4px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            margin-right: 3px;
            cursor: pointer;
            transition: 0.2s all;

            &:hover {
                background: #fff;
                border: 1px solid rgba(0, 0, 0, 0);

                transition: 0.2s all;
            }
        }
    }

    a {
        color: #333;
        margin: 5px 0;
        text-decoration: none;
        box-sizing: border-box;
        padding: 5px;
        border-radius: 5px;
        background: #fff;
        width: fit-content;
    }

    span b {
        color: #666;
        font-size: 12px;
        cursor: pointer;
        border-bottom: 0 solid transparent;

        transition: 0.1s all;

        &:hover {
            border-bottom: 2px solid rgba(0, 0, 0, 0.87);
            transition: 0.1s all;
        }
    }
}

.modalOverlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, 1);
    opacity: 0;
    visibility: hidden;
    transition: 0.2s all;
}

.modalOverlayOpened {
    opacity: 0.94;
    visibility: visible;

    transition: 0.2s all;
}

.realEstateItem {
    position: relative;
    width: 100%;
    background: #ffffff;
    border-radius: 10px;
    cursor: pointer;

    .realEstateHeader {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
        padding: 10px;

        .realEstateHeaderLeft {
            img {
                width: 35px;
                height: 35px;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 10px;
            }
        }

        .realEstateHeaderRight {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            h3 {
                margin: 0;
                font-size: 19px;
                color: #0a2463;
            }
        }
    }

    .imageItem {
        width: 100%;
        position: relative;

        img {
            display: block;
            width: 100%;
            height: 240px;
            object-fit: cover;
        }

        h2.realEstateTitle {
            position: absolute;
            top: 10px;
            left: 10px;
            z-index: 100;
            background: #146fd1;
            padding: 9px 20px 5px;
            font-size: 20px;
            border-radius: 5px;
            color: #fff;
            margin: 0;
            font-weight: bold;
        }
    }

    .prices {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px;
        box-sizing: border-box;

        .listRoomPrices {
            width: 100%;
            display: flex;
            flex-direction: column;

            h3 {
                margin: 0;
            }

            .listRoomPriceItem {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                span {
                    width: fit-content;
                    display: flex;
                    white-space: nowrap;
                    color: #a7a7a7;
                }

                span.spanDots {
                    border-bottom: 1px dotted #cbcbcb;
                    width: -webkit-fill-available;
                    height: 8px;
                }
            }
        }
    }

    .realEstateItemOverlay {
        width: 100%;
        box-sizing: border-box;
        padding: 4px 10px;
        display: flex;
        flex-direction: column;
        align-items: start;

        .realEstateTitle {
            position: absolute;
            top: 10px;
            color: #fff;
            border-radius: 4px;
            background: rgba(17, 17, 17, 0.28);
            font-size: 16px;
            font-weight: 500;
            padding: 3px 7px;
        }
        .bookingInfo {
            position: absolute;
            top: 65px;
            right: 10px;
            color: #fff;
            font-size: 16px;
            font-weight: 500;
            span {
                display: block;
                background: #29930f;
                border-radius: 4px;
                padding: 3px 7px;
                max-width: 100px;
                text-align: center;
                width: 100%;
                box-sizing: border-box;
                &:last-child {
                    background: #dda004;
                }
                &:not(:last-child) {
                    margin-bottom: 7px;
                }
            }
        }

        .realEstateItemButtons {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
                display: flex;
                justify-content: flex-end;
                margin-left: auto;

                button {
                    border: none;
                    display: flex;
                    width: 46px;
                    height: 36px;
                    border-radius: 5px;
                    cursor: pointer;
                    position: relative;
                    justify-content: center;
                    align-items: center;
                    background: #0a2463;
                    svg {
                        fill: #fff;
                    }
                    &:last-child {
                        margin-left: 12px;
                    }
                }
            }
        }

        .realEstateItemUser {
            p {
                color: #081e51;
                font-size: 16px;
                margin: 0;
                line-height: 18px;
            }

            a {
                color: #081e51;
                display: block;
                font-size: 16px;
                text-decoration: none;
            }
        }
    }
}

.realEstateItemZIndex {
    z-index: 1010;
    box-shadow: 0 0 40px rgba(255, 255, 255, 0.18);

    transform: scale(1.09);
    transition: 0.1s all;
}

.formUser {
    position: fixed;
    top: 0;
    left: -400px;
    width: 400px;
    height: 100vh;
    z-index: 2000;
    background: #fff;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    flex-direction: column;

    transition: 0.3s all;

    input {
        width: 100%;
        height: 44px;
        padding: 0 15px;
        margin: 5px 0;
        box-sizing: border-box;
        border: none;
    }

    input[type='text'] {
        background: #e5e5e5;
    }
}

.formUserOpened {
    left: 0px;

    transition: 0.3s all;
}

.cardWrapper {
    position: relative;
    width: 100%;
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid rgba(0,0,0,0.04);

    .cardOverlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.4);
        z-index: 100;
        border-radius: 10px;
        opacity: 0;
        backdrop-filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.11));
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        gap: 10px;
        flex-direction: column;
        justify-content: space-between;

        transition: .3s;

        .cardOverlayMenu {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 8px;

            span {
                width: 100%;
                height: 55px;
                background-color: rgba(0,0,0,0.64);
                border: 1px solid rgba(0,0,0,0.1);
                color: #fff;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 15px;
                opacity: 0.77;
    
                transition: .3s;
    
                &:hover {
                    background-color: rgba(0,0,0,0.45);
                    box-shadow: 0 0 14px rgba(0,0,0,0.21);
                    opacity: 1;
    
                    transition: .3s;
                }
            }

        }

        .cardOverlayFooter {
            display: grid;
            width: 100%;
            gap: 10px;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
            box-sizing: border-box;
        }

    }

    &:hover .cardOverlay {
        opacity: 1;
        backdrop-filter: blur(8px);
        box-shadow: 0 0 15px rgba(0,0,0,0.17);

        transition: .3s;
    }
}
