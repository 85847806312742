.managerWrapper {
    width: 100%;
    .headManagerPage {
        margin: 0 auto 15px;
        width: 100%;
        color: #0a2463;
        font-weight: 500;
        font-size: 1.25em;
    }

    .formManager {
        width: 100%;
        margin: 0 auto;

        input {
            width: 100%;
            margin-bottom: 5px;
        }

        .formManagerPWD {
            display: flex;
            width: 100%;
            flex-direction: row;
            justify-content: space-between;

            input {
                width: 49%;
            }
        }

        .section {
            margin-top: 25px;
            display: flex;
            flex-direction: column;
            width: 100%;
            background: #efefef;
            border-radius: 5px;
            box-sizing: border-box;
            padding: 15px;
            margin-bottom: 7px;
            .sectionTitle {
                font-size: 0.9375em;
                font-weight: bold;
                color: #0a2463;
            }
            .sectionSubtitle {
                font-size: 0.875em;
            }
            .realEstateItems {
                width: 100%;
                background: #fff;
                padding: 10px;
                border-radius: 6px;
                box-sizing: border-box;
                position: relative;
                z-index: 100;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-gap: 15px;

                .realEstateItem {
                    width: 100%;
                    height: 180px;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    border-radius: 6px;

                    .realEstateItemTitle {
                        display: flex;
                        width: 100%;
                        height: 50px;
                        background: rgba(255, 255, 255, 0.7);
                        justify-content: center;
                        align-items: center;
                        color: #333;
                        text-align: center;
                        margin: 0;
                        font-size: 15px;
                        font-weight: bold;
                    }
                }
            }
            .addComplex {
                cursor: pointer;
                border: none;
                background: #0a2463;
                color: #fff;
                font-weight: 500;
                padding: 8px 0;
                border-radius: 6px;
                width: 25%;
                transition: all ease 0.15s;

                &:hover {
                    background: lighten(#0a2463, 5);
                    transition: all ease 0.2s;
                }
            }
        }

        input[type='submit'] {
            cursor: pointer;
            width: 140px;
            height: 40px;
            font-size: 16px;
            background: #5ebf15;
            color: #fff;
            margin-top: 30px;
            border-radius: 7px;
        }
    }
}
