.contentModal {

	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	.formLogin {
		width: 500px;
		height: 400px;
		border-radius: 10px;
		box-sizing: border-box;
		padding: 15px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		h1 {
			margin: 10px 0 30px 0;
			color: rgba(0,0,0,0.2);
			font-weight: 300;
		}
	}

}