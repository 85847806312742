.wrapper {
    width: 100%;

    .header {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .wrapperFormTemplate {
        width: 100%;
        box-sizing: border-box;
        padding: 10px;
        border: 1px solid rgba(0,0,0,0.14);
        border-radius: 4px;

        .skeletonsWrapper {
            width: 100%;

            .skeletons {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    
                .item {
                    width: 100%;
                    height: 50px;
                    background-color: rgb(230, 230, 230);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    cursor: pointer;
                }
            }
        }

    }

    .wrapperTemplate {
        width: 100%;
        box-sizing: border-box;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #f0f0f0;
    }
}

.dynFileWrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    gap: 7px;

    .item {
        width: 100%;
        position: relative;
    }
}
