.realEstateItems {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;

    @media screen and (max-width: 1920px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.pagination {
    width: 100%;
    margin: 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
