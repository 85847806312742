.wrapper {
    width: 100%;

    .content {
        width: 100%;
        min-height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.listFuncs {
    h5 {
        margin: 0 0 3px 0;
    }
    p {
        margin: 0 0 3px 0;
    }

    h3 {
        margin-top: 15px;
    }
}
