.plans {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 999999;
    display: flex;
    opacity: 1;
    visibility: visible;
    justify-content: center;
    align-items: center;
    background-color: rgba(10, 36, 99, 0.39);
    backdrop-filter: blur(10px);
    transition: 0.35s all;

    .content {
        padding: 10px 20px;
        width: 55vw;
        min-width: 550px;
        background-color: #fff;
        border-radius: 20px;

        .header {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            h5 {
                margin: 0;
                color: #0a2463;
                font-size: 20px;
            }

            svg {
                cursor: pointer;
                color: #0a2463;
            }
        }

        .listPlans {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 10px;

            .plan {
                width: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: space-between;

                div {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    .planContent {
                        width: 100%;
                        padding: 7px;
                        border: 1px solid #a9a9a9;
                        border-radius: 5px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        span {
                            position: relative;
                            display: block;
                            margin-left: 15px;
                            color: #000;
                            font-size: 16px;
                            margin-top: 7px;
                            margin-bottom: 7px;
                            line-height: 20px;

                            &::after {
                                content: '';
                                width: 11px;
                                height: 11px;
                                position: absolute;
                                border-radius: 50%;
                                top: 50%;
                                margin-top: -5px;
                                left: -15px;
                                background-color: #4079c6;
                            }
                        }
                    }
                    h5 {
                        text-transform: uppercase;
                        color: #0a2463;
                        margin: 10px 0;
                        font-weight: bold;
                    }
                }

                .paymentButton {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    flex-direction: column;
                    padding: 5px 7px;

                    h3 {
                        margin: 0;
                        font-weight: normal;
                        color: #0a2463;
                        font-size: 25px;
                    }
                }
            }
        }
    }
}

.disabled {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    visibility: hidden;
    backdrop-filter: blur(0px);
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
}
