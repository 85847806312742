.columnGrid {
    margin: 10px;
    padding: 15px;

    .blockTitle {
        font-size: 16px;
        font-weight: 500;
        color: #3b455e;
        background: #f2f2f2;
        width: 100%;
        text-align: center;
        padding: 3px 0;
        border-radius: 5px;
        margin-bottom: 5px;
    }
}
.columnGridChess {
    width: 100%;
    display: flex;
    min-width: 120px;
    flex-direction: column;
    background: rgba(#abbada, 0.13);
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;

    .columnGridFloor {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;

        > span {
            position: absolute;
            left: -36px;
            font-size: 12px;
            color: #4b657f;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
        }

        .floorClear {
            width: 100%;
            height: 40px;
            background: #1977f3;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 5px 0;
            border-radius: 5px;

            /*text*/
            h6 {
                color: rgba(255, 255, 255, 0.67);
                text-align: center;
            }
        }

        .columnGridFlat {
            border: none;
            width: 36px;
            margin: 5px;
            height: 36px;
            border-radius: 6px;
            justify-content: center;
            align-items: center;
            display: flex;
            cursor: pointer;
            user-select: none;
            position: relative;

            h5 {
                display: block;
                box-sizing: border-box;
                padding: 10px 0px;
                margin: 0;
                font-size: 11px;
                color: #fff;
                font-weight: bold;
                word-break: break-all;
                text-align: center;
            }

            > input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                text-align: center;
                z-index: 999;
                background: #1977f3;
                outline: none;
                border: none;
                border-radius: 5px;
                color: #fff;
            }

            .changePriceBlock {
                min-width: 320px;
                position: absolute;
                top: -15px;
                left: 100%;
                margin-left: 5px;
                background: #0a2463;
                box-sizing: border-box;
                padding: 10px 10px 20px 10px;
                border-radius: 8px;
                z-index: 1111;
                display: flex;
                flex-direction: column;

                > h4 {
                    margin-top: 20px;
                    font-weight: bold;
                    font-size: 17px;
                    color: #fff;
                }

                .changePriceBlockCloseIcon {
                    position: absolute;
                    top: 11px;
                    right: 11px;
                    color: #fff;
                }

                .changePriceBlockForm {
                    display: flex;
                    flex-direction: row;
                    width: 100%;

                    .changePriceBlockFormLeft {
                        margin-right: 15px;

                        p {
                            font-size: 18px;
                            margin: 0;
                            font-weight: normal;
                            color: #fff;
                        }
                    }

                    .changePriceBlockFormRight {
                        width: 60%;

                        > input[type='text'] {
                            width: 154px;
                            height: 30px;
                            border-radius: 5px;
                            border: 1px solid #fff;
                            background: transparent;
                            outline: none;
                            margin-bottom: 7px;
                            text-align: center;
                            color: #fff;
                        }
                        > input[type='submit'] {
                            width: 154px;
                            height: 30px;
                            border-radius: 5px;
                            background: #1977f3;
                            color: #fff;
                            outline: none;
                            border: none;
                            color: #fff;
                        }
                    }
                }
            }

            .changePriceBlockRightMargin {
                left: auto;
                right: 100%;
                margin-right: -15px;
                top: 15px;
            }

            .flatContentMenu {
                background: #f9f9f9;
                border: 1px solid #acacac;
                border-radius: 4px;
                position: absolute;
                border-radius: 2px;
                z-index: 999;
                top: 0;
                left: 100%;

                > p {
                    width: 150px;
                    display: block;
                    padding: 10px;
                    color: #333;
                    cursor: pointer;
                    background: rgba(0, 0, 0, 0);
                    margin: 0;

                    transition: 0.2s all;

                    &:hover {
                        background: #f3f3f3;

                        transition: 0.2s all;
                    }
                }
            }

            .flatContentMenuRight {
                right: 100%;
                left: auto;
                top: calc(-100% - 10px);
            }
        }

        .flatSold {
            background: #eef4ff;
            border: 1px solid #d8e1ea;
            box-sizing: border-box;

            h5 {
                font-weight: 500;
                color: #4b657f;
            }
        }

        .flatBooking {
            background: #f8ef29;

            h5 {
                font-weight: 500;
                color: #4b657f;
            }
        }

        .flatNotSold {
            background: #5acd3e;

            h5 {
                font-weight: 500;
            }
        }

        .flatAnimation {
            background: #5acd3e;
            animation-name: LightBackFlat;
            animation-iteration-count: infinite;
        }

        .flatAnimationRealtor {
            background: #f8ef29;
            animation-name: LightBackFlatRealtor;
            animation-iteration-count: infinite;

            h5 {
                font-weight: 500;
                color: #333;
            }
        }

        .bookingStyle {
            background: #f8ef29;

            h5 {
                font-weight: 500;
                color: #333;
            }
        }

        .flatSecondary {
            background: #d7ab11;
            box-sizing: border-box;
            border: none;

            h5 {
                font-weight: 500;
                color: #fff;
            }
        }

        .bookingPopup {
            position: relative;

            span {
                position: absolute;
                background: #1977f3;
                color: #fff;
                border-radius: 5px;
                font-size: 15px;
                opacity: 0;
                visibility: hidden;
                width: 150px;
                height: 35px;
                display: flex;
                justify-content: center;
                align-items: center;
                top: 135%;
                z-index: 100;

                transition: 0.05s all;

                &:after {
                    content: '';
                    width: 38px;
                    height: 21px;
                    top: -21px;
                    left: 50%;
                    margin-left: -17px;
                    position: absolute;
                    background: url('../../../Assets/arrow-up.png');
                }
            }

            &:hover span {
                opacity: 1;
                top: 118%;
                visibility: visible;
                z-index: 100;

                transition: 0.05s all;
            }
        }
    }

    .modalSettingFlat {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 1000;
        background: rgba(0, 0, 0, 0);
        opacity: 0;
        visibility: hidden;

        transition: 0.2s all;

        .settingFlatForm {
            width: 35vw;
            max-width: 550px;
            height: 100vh;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 100;
            background: #fff;
            box-sizing: border-box;
            padding: 20px;

            hr {
                width: 100%;
                margin: 15px 0 0 0;
            }

            span {
                cursor: pointer;
                background: #2f9715;
                color: #fff;
                border-radius: 5px;
                font-size: 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: fit-content;
                padding: 8px 11px;
            }

            h5 {
                font-size: 18px;
                font-weight: 100;
                margin: 0 0 15px 0;
            }

            section {
                display: flex;
                flex-direction: column;
                width: 100%;
                background: #efefef;
                border-radius: 5px;
                box-sizing: border-box;
                padding: 15px;
                margin-bottom: 7px;

                p {
                    margin: 0 0 5px 0;
                }

                input {
                    width: 50%;
                    height: 40px;
                    border: 1px solid rgba(0, 0, 0, 0.3);
                    border-radius: 4px;
                    margin-right: 5px;
                    padding: 0 15px;
                    font-size: 17px;
                    outline: none;
                }

                input[type='checkbox'] {
                    width: 15px;
                    height: 15px;
                }
            }

            .sectionRow {
                flex-direction: row;
                align-items: center;

                p {
                    margin: 0 10px 0 0;
                }
            }

            .sectionNotification {
                background: transparent;
                padding: 10px 0 0 0;

                .managerItem {
                    width: 100%;
                    margin: 5px 0;
                    box-sizing: border-box;
                    background: #eee;
                    border-radius: 5px;
                    padding: 10px;

                    p {
                        margin: 0;
                    }

                    a {
                        color: #555;
                        font-weight: bold;
                        font-size: 15px;
                        margin-top: 2px;
                    }
                }

                h5 {
                    font-weight: 600;
                    margin: 0 0 5px 0;
                }

                .notificationBlock {
                    width: 100%;
                    box-sizing: border-box;
                    padding: 15px;
                    background: #0a2463;
                    border-radius: 6px;
                    margin: 0 0 15px 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    p {
                        margin: 0 0 10px 0;
                        color: #fff;
                        font-weight: normal;
                    }

                    b {
                        cursor: pointer;
                        width: 80px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 30px;
                        border-radius: 5px;
                        margin: 5px 10px;
                        font-weight: normal;
                        color: #fff;
                    }

                    b:nth-child(1) {
                        background: #5bcd3e;
                    }

                    b:nth-child(2) {
                        background: #bd0808;
                    }

                    span {
                        width: 70%;
                        box-sizing: border-box;
                        border-radius: 5px;
                        background: red;
                        margin: 5px 0;
                    }

                    span.WAITING {
                        background: #4079c6;
                    }
                    span.CANCELED {
                        background: #bd0808;
                    }
                    span.SUCCESS {
                        background: #5bcd3e;
                    }

                    .notificationBlockRow {
                        display: flex;
                        width: 100%;
                        flex-direction: row;
                        justify-content: center;
                    }
                }
            }
        }

        .modalSettingFlatOverlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }
    }

    .modalSettingFlatOpened {
        background: rgba(0, 0, 0, 0.87);
        opacity: 1;
        visibility: visible;

        transition: 0.2s all;
    }
}

@keyframes LightBackFlat {
    0% {
        background: #5acd3e;
    }

    50% {
        background: #187600;
    }

    100% {
        background: #5acd3e;
    }
}

@keyframes LightBackFlatRealtor {
    0% {
        background: #f8ef29;
    }

    50% {
        background: #ddd52c;
    }

    100% {
        background: #f8ef29;
    }
}
