.wrapper {
    width: 100%;
    min-height: 70vh;

    .header {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .headerRight {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }

    .content {
        width: 100%;

        .filter {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;

            > div {
                width: 100%;

                .datePicker {
                    width: 100%;
                }
            }
        }

        .infoBlock {
            width: 100%;
            height: 450px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .list {
            width: 100%;
            display: flex;
            flex-direction: column;
        }
    }
}

.blockPriceLeads {
    margin: 10px 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.04);
    box-sizing: border-box;
    padding: 15px;
}

.infoblock {
    width: 100%;
    height: 450px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.list {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 10px 0;

    .item {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0;
        justify-content: space-between;
        border: 1px solid #4079c6;
        border-radius: 3px;
        margin-bottom: 2px;
        color: #4079c6;
        cursor: pointer;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0);

        transition: 0.2s all;

        &:hover {
            border: 1px solid #eee;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.175);

            transition: 0.2s all;
        }

        .section {
            width: 100%;
            padding: 10px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            p {
                margin: 0;
            }
        }

        .line {
            width: 1px;
            height: 41px;
            background-color: #4079c6;
            display: flex;
        }
    }
}

.items {
    width: 100%;
    display: flex;
    flex-direction: column;

    .itemStatus {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 6px 0;
        background-color: rgba(0, 0, 0, 0.02);
        box-sizing: border-box;
        padding: 7px;
        border-radius: 3px;
        cursor: pointer;

        .itemStatusLeft {
            display: flex;
            flex-direction: row;
            align-items: center;

            p {
                margin: 0;
            }

            .itemStatusLeftColor {
                width: 35px;
                height: 35px;
                border-radius: 50%;
                margin-right: 15px;
            }
        }
    }
}
