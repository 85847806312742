.layout {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: relative;
}

.settingLinks {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  background: #eee;
  border-radius: 4px;

  a {
    display: block;
    padding: 10px;
    border-radius: 4px;
    text-decoration: none;

    transition: 0.4s all;
  }

  .activeLink {
    background: #333;
    color: #fff;

    transition: 0.4s all;
  }
}

.blockSections {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #eee;
  margin-top: 15px;
  border-radius: 5px;

  .blockSection {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    padding: 5px 15px;
    border-radius: 4px;
    margin: 0;

    h6 {
      display: flex;
      width: 140px;
    }

    b {
      color: rgba(0, 0, 0, 0.5);
    }

    input {
      width: 100%;
      height: 44px;
      border: none;
      padding: 0 15px;
      box-sizing: border-box;
      background: #fff;
      border-radius: 5px;
    }
  }

  .blockSectionErrors {
    background: red;
    color: #fff;
  }
}

.modalRealEstateItems {
  width: 60vw;
  height: 90vh;
  background: #fff;
  overflow-y: auto;
  box-sizing: border-box;
  position: relative;
  z-index: 100;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
  grid-template-rows: max-content;

  .modalRealEstateItem {
    width: 100%;
    height: 230px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    transition: 0.2s all;

    &:hover {
      .modalRealEstateItemOverlow {
        background: rgba(255, 255, 255, 0);

        transition: 0.3s all;
      }

      h3 {
        padding: 10px 15px;
        background: rgba(255, 255, 255, 0.88);
        border-radius: 7px;

        transition: 0.27s all;
      }
    }

    h3 {
      display: block;
      padding: 0;
      background: transparent;
      z-index: 100;
      text-align: center;
      font-size: 17px;

      transition: 0.27s all;
    }

    .modalRealEstateItemOverlow {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(255, 255, 255, 0.87);

      transition: 0.3s all;
    }
  }
}

.modalRealEstate {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11000;
  background: rgba(0, 0, 0, 0.77);
  opacity: 0;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: 0.2s all;

  .modalRealEstateOverlay {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.RealEstateItems {
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  position: relative;
  z-index: 100;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 15px;

  .RealEstateItem {
    width: 100%;
    height: 180px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    h4 {
      display: flex;
      width: 100%;
      height: 50px;
      background: rgba(255, 255, 255, 0.7);
      justify-content: center;
      align-items: center;
      color: #333;
      text-align: center;
      margin: 0;
      font-size: 15px;
      font-weight: bold;
    }
  }
}

.modalRealEstateOpened {
  opacity: 1;
  visibility: visible;

  transition: 0.2s all;
}

.formRealtor {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-right: 15px;

  input {
    width: 100%;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    padding: 0 15px;
    margin: 10px 0;
    height: 35px;
    outline: none;
  }

  input[type="submit"] {
    width: 170px;
    height: 40px;
    cursor: pointer;
    outline: none;
    background: #a4d14c;
    border-bottom: 3px solid #333;
    color: #fff;
    border-radius: 5px;
  }

  .formRealtorPassword {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    input {
      width: 48%;
    }
  }

  section {
    h5 {
      font-size: 15px;
      font-weight: bold;
      color: #4e719e;
      margin-top: 20px;
    }

    span {
      display: block;
      padding: 6px 20px;
      background: #1977f3;
      cursor: pointer;
      width: fit-content;
      color: #fff;
      border-radius: 4px;
    }
  }
}

.error {
  width: 100%;
  margin-top: 15px;

  p {
    display: block;
    width: 100%;
    color: #fff;
    background: Red;
    box-sizing: border-box;
    padding: 15px;
    border-radius: 7px;
    font-size: 13px;
  }
}