.layout {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    background: url('../../../Assets/login-background.jpg');
    background-size: cover;
}

.formLogin {
    position: relative;
    left: 15%;
    width: 500px;
    height: 400px;
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;

    h1 {
        color: #fff;
        margin: 0;
    }

    .logo {
        display: block;
        margin-bottom: 8px;
        text-align: left;
    }

    .title {
        color: #fff;
        margin-bottom: 99px;
        font-size: 50px;
        font-weight: 600;
    }

    .subtitle {
        color: #fff;
        font-size: 25px;
        margin-bottom: 38px;
        font-weight: 600;
    }

    .form {
        width: 290px;
        .label {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            background: #fff;
            border: 1px solid #a9bdcf;
            padding: 16px;
            border-radius: 4px;
            &:nth-child(2) {
                margin-bottom: 38px;
            }
            img {
                display: block;
                margin-right: 16px;
            }
            input {
                width: 100%;
                display: block;
                border: none;
                box-sizing: border-box;
                font-size: 17px;
                outline: none;
                font-weight: 500;
                color: #333;

                &::placeholder {
                    font-weight: 500;
                    color: #333;
                }
            }
        }

        input[type='submit'] {
            border: none;
            outline: none;
            cursor: pointer;
            background: #fff;
            color: #4079c6;
            width: 100%;
            padding: 11px 0;
            font-weight: 600;
            font-size: 16px;
            border-radius: 26px;
        }
    }
}
