@mixin notificationText($color) {
    box-sizing: border-box;
    border-radius: 5px;
    color: $color;
    border: 1px solid $color;
    padding: 5px 0;
    width: 100%;
    text-align: center;
    font-size: 1em;
}

.notificationBlock {
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    background: rgba(10, 36, 99, 0.06);
    border-radius: 8px;
    margin: 00;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .bookingBlock {
        width: 100%;
        box-sizing: border-box;
        padding: 10px;
        margin-bottom: 10px;

        span {
            width: 100%;
            display: block;
        }
    }

    .removeNotification {
        cursor: pointer;
        position: absolute;
        top: 6px;
        right: 6px;
        border: none;
        background: none;
    }

    .openFlatButton {
        cursor: pointer;
        width: 100%;
        border: none;
        padding: 8px 0;
        border-radius: 6px;
        color: #fff;
        background: #0a2463;
        transition: all ease 0.15s;
    }

    .requestText {
        display: block;
        margin: 0;
        font-weight: normal;
        color: #102a43;
        font-size: 17px;
        text-align: center;

        strong {
            display: block;
            margin-top: 4px;
            font-size: 25px;
        }
    }

    .notificationBlockRow {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        button {
            cursor: pointer;
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            font-weight: 500;
            font-size: 18px;
            border-radius: 5px;
            margin: 5px 10px;
            background: none;
        }

        button:nth-child(1) {
            color: #4e8d4d;
            border: 1px solid #4e8d4d;
        }

        button:nth-child(2) {
            color: #bd0808;
            border: 1px solid #bd0808;
        }
    }

    .success {
        @include notificationText(#5bcd3e);
    }

    .waiting {
        @include notificationText(#586ce9);
    }

    .canceled {
        @include notificationText(#bd0808);
    }
    .cancelRequest {
        cursor: pointer;
        background: none;
        margin-top: 9px;
        @include notificationText(#727272);
    }
}

.realtorContacts {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #eee;
    padding: 10px 0;

    h5 {
        margin: 0;
        text-align: center;
    }
}
