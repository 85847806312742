.managerItem {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 10px 10px 5px 10px;
    background: #f4f4f4;
    border-radius: 6px;

    p {
        margin: 0;
    }

    .links {
        margin-bottom: 5px;
        display: flex;
        flex-direction: column;
        a {
            text-decoration: none;
            color: #0a2463;
            margin-right: 5px;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .openComplex {
        background: #0a2463;
        color: #fff;
        border: none;
        padding: 5px 8px;
        border-radius: 4px;
        margin-bottom: 5px;

        transition: all ease 0.15s;

        &:hover {
            background: lighten(#0a2463, 6);
            transition: all ease 0.2s;
        }

        &:not(:last-child) {
            margin-right: 5px;
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        button {
            border: none;
            padding: 5px;
            background: #fff;
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            border-radius: 4px;
            svg {
                width: 100%;
                height: 100%;
                cursor: pointer;
                transition: 0.15s ease all;
            }
            &:hover {
                background: darken(#fff, 8.5);
                transition: 0.2s ease all;
            }
        }
    }
}
