.chessWrapper {
    width: 100%;
    margin: 10px 0;

    .header {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 15px 0;

        h1 {
            margin: 0;
            font-size: 20px;
        }

        .navigation {
            display: flex;
            flex-direction: row;
            gap: 10px;
        }
    }

    .content {
        width: 100%;

        .formItems {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 10px;
        }

        .loading {
            width: 100%;
            height: 500px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .list {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 10px;

            .item {
                width: 100%;
                border: 1px solid rgba(0,0,0,0.3);
                border-radius: 5px;
                cursor: pointer;

                .image {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .description {
                    width: 100%;
                    box-sizing: border-box;
                    padding: 0 10px 10px 10px;
                }
            }
        }
    }
    
}