@mixin square($bgColor) {
    content: '';
    display: block;
    width: 22px;
    height: 22px;
    background: $bgColor;
    margin-left: 4px;
}

.chessWrapper {
    width: 100%;
    overflow-y: hidden;
    overflow-x: overlay;
    .chessHeader {
        display: flex;
        justify-content: space-between;
        position: sticky;
        left: 0;

        .chessHeaderLeft {
            display: flex;
            flex-direction: row;
            align-items: center;

            .chessHeaderLeftNotification {
                margin-left: 10px;
                cursor: pointer;
                position: relative;
                top: 3px;

                svg {
                    font-size: 22px;
                }
            }
        }

        .complexTitle {
            color: #0a2463;
            font-size: 18px;
            font-weight: 500;
            margin-top: 12px;
        }
        .chessDescribe {
            display: flex;
            span {
                display: flex;
                align-items: center;
                color: #7d7d7f;
                font-weight: 500;
                font-size: 14px;
                &:not(:last-child) {
                    margin-right: 18px;
                }
                &:first-child:after {
                    @include square(#f8ef29);
                }
                &:nth-child(2):after {
                    @include square(#eef4ff);
                }
                &:nth-child(3):after {
                    @include square(#5bcd3e);
                }
                &:nth-child(4):after {
                    @include square(#d7ab11);
                }
            }
        }
    }
}

.chessContent {
    width: 100%;
    overflow: hidden;
    height: auto;
    min-height: calc(100vh - 200px);
    position: relative;

    .chessContentHorizontalScroll {
        width: 100%;
        display: flex;
        flex-direction: row;
    }
}
