@font-face {
    font-family: 'ProximaNova';
    src: url(../fonts/ProximaNova-Regular.ttf);
    font-display: swap;
}
@font-face {
    font-family: 'ProximaNova';
    src: url(../fonts/ProximaNova-Semibold.ttf);
    font-weight: 500;
    font-display: swap;
}
@font-face {
    font-family: 'ProximaNova';
    src: url(../fonts/ProximaNova-Bold.ttf);
    font-weight: 600;
    font-display: swap;
}
@font-face {
    font-family: 'ProximaNova';
    src: url(../fonts/ProximaNova-Extrabold.ttf);
    font-weight: 700;
    font-display: swap;
}

* {
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-family: 'ProximaNova', sans-serif;
}

body {
    background: #f2f4f6;
    overflow-x: hidden;
    overflow-y: scroll;
}

::-webkit-scrollbar {
	width: 8px;
}

::-webkit-scrollbar-track {
	background: #333;
}

::-webkit-scrollbar-thumb {
	background-color: #bbb;
	border-radius: 20px;
}

::-moz-scrollbar {
	width: 8px;
}

::-moz-scrollbar-track {
	background: #333;
}

::-moz-scrollbar-thumb {
	background-color: #222;
	border-radius: 20px;
}

::-o-scrollbar {
	width: 8px;
}

::-o-scrollbar-track {
	background: #333;
}

::-o-scrollbar-thumb {
	background-color: #222;
	border-radius: 20px;
}

::-ms-scrollbar {
	width: 8px;
}

::-ms-scrollbar-track {
	background: #333;
}

::-ms-scrollbar-thumb {
	background-color: #222;
	border-radius: 20px;
}

img.ant-image-preview-img {
    background-color: #fff;
    display: block;
    padding: 20px;
}

.ant-form-item {
    margin-bottom: 10px;
}

.ant-form-item-label {
    position: relative;
    top: 4px;
}

.ant-form-item-label > label {
    max-height: 25px;
}

.ant-form-item, .ant-picker {
    width: 100%;
    margin-bottom: 5px;
}

.ant-upload-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 10px 0;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container {
    width: calc(25% - 2px);
    margin: 1px;
    height: 85px;
}

#fr-logo {
    display: none;
}

.ce-editorjsColumns_col {
    border-radius: 5px;
    border: 1px solid rgba(0,0,0,0.16);
}
.a_l_helper_border {
    border: 1px solid #d2d2d2;
    border-radius: 5px;
}
