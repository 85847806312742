.realEstateItems {
    width: 100%;
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .right {
        display: flex;
        gap: 10px;
        flex-direction: row;
    }
}

.sectionForm {
    padding: 10px;
    display: flex;
    width: 100%;
    border: 1px dashed #d3d3d3;
    border-radius: 5px;
    flex-direction: column;
    margin: 10px 0;
}

.fileWrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}

.flexRowWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
}
