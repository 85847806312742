.settingsWrapper {
	background: #ffffff;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
	border-radius: 10px;
	padding: 0;

	.settingsTitle {
		color: #0a2463 !important;
		font-weight: 500 !important;
		font-size: 20px !important;
	}

	.settingLinks {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		box-sizing: border-box;
		background: #eee;
		border-radius: 4px;

		a {
			display: block;
			padding: 10px;
			border-radius: 4px;
			text-decoration: none;
			color: #0a2463;
			transition: 0.4s all;
		}

		.activeLink {
			background: #0a2463;
			color: #fff;

			transition: 0.4s all;
		}
	}

	.blockSections {
		width: 100%;
		display: flex;
		flex-direction: column;
		margin-top: 15px;
		border-radius: 5px;

		.blockSection {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			box-sizing: border-box;
			margin: 0 0 5px 0;
			color: #67738f;
			
		}

		.blockSectionErrors {
			background: red;
			color: #fff;
			width: 100%;
			padding: 10px;
			border-radius: 5px;

			p {
				margin: 0;
			}
		}
	}
}
