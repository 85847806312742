.wrapper {
    width: 100%;

    .title {
        color: #0a2463 !important;
        font-weight: 300 !important;
        font-size: 20px !important;
    }

    .content {
        width: 100%;

        .section {
            width: 100%;
            min-height: 100px;
            display: flex;
            flex-direction: column;
            border: 1px solid #e7e7e7;
            background-color: #f9f9f9;
            border-radius: 4px;
            box-sizing: border-box;

            .header {
                width: 100%;
                box-sizing: border-box;
                padding: 10px;
                background-color: #6679eb;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }

            .info {
                width: 100%;
                margin: 5px 0;
                display: flex;
                flex-direction: column;
                box-sizing: border-box;
                padding: 2px 10px;

                .text {
                    font-size: 13px;
                    color: #939393;
                }
            }
        }
    }

    .contentGrid {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        gap: 20px;

        .link {
            display: block;
            padding: 5px 10px;
            background-color: #e5e5e5;
            border-radius: 5px;

            transition: 0.25s;

            &:hover {
                background-color: #f5f5f5;
                box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
            }

            .logo {
            }

            .logob24 {
                width: 100%;
                height: 55px;
                background: url(https://files.logovo.kz/uploads/bitrix-logo.png)
                    center / contain no-repeat;
            }
        }
    }
}

.textHeader {
    color: #eee;
    font-size: 17px;
}

.textInfo {
    font-size: 17px;
    font-weight: bold;
    color: #616161;
}

.statusSection {
    width: 100%;
    position: relative;
    border-radius: 5px;
    background-color: #f3f3f3;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px;
    margin: 10px 0;

    .buttonUpdatStatuses {
        float: right;
    }

    .wrapperStatus {
        width: 100%;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 10px;
        margin-top: 20px;
        max-width: 100%;
        overflow-x: auto;

        .content {
            width: fit-content;
            display: flex;
            flex-direction: row;
            gap: 15px;

            .item {
                width: 250px;
                box-sizing: border-box;
                padding: 5px;
                margin: 5px 0;
                border-radius: 7px;

                &:last-child {
                    border-right: 1px solid transparent;
                }

                h5 {
                    font-size: 10px;
                    margin-bottom: 6px;
                    text-transform: uppercase;
                    color: #000;
                    font-weight: bold;
                }

                .logovoStatusList {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }
}

.wrapperStatus::-webkit-scrollbar {
    height: 5px;
}

.wrapperStatus::-webkit-scrollbar-thumb {
    border-radius: 10px;
}

.resetBlock {
    display: flex;
    flex-direction: column;
    gap: 10px;

    button,
    span {
        width: fit-content;
    }
}
