.fullPageSvgBlock {
    background: #eee;
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;

    span {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }

    .imgFp {
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 0;

        & svg {
            width: auto;
            height: 96vh;
            max-width: 90%;
            display: block;
            margin: 0 auto;
        }
    }
}

.settingFlatForm {
    width: 100%;
    background: #fff;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 0px;
    position: relative;

    .closeSidebar {
        cursor: pointer;
        position: absolute;
        top: 8px;
        right: 8px;
        border: none;
        background: none;
    }

    .flatControl {
        padding: 16px 0 8px;
        border-bottom: 1px solid #d6d6d6;
        margin-bottom: 21px;
        & > div {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 8px;
            label {
                color: #102a43;
                font-weight: 500;
            }
            .soldControl {
                display: flex;
                align-items: center;
                input[type='checkbox'] {
                    margin-left: 10px;
                    height: 18px;
                    width: 18px;
                    border-radius: 6px;
                }
            }
            .priceControl {
                margin-left: auto;
                display: flex;
                align-items: center;
                input {
                    border: 1px solid #102a43;
                    box-sizing: border-box;
                    border-radius: 6px;
                    padding: 2px 5px;
                    margin: 0 5px;
                    max-width: 62px;
                }
            }
        }
        .updateFlatData {
            border: none;
            cursor: pointer;
            background: #2f9715;
            color: #fff;
            border-radius: 5px;
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 8px 0;
            box-sizing: border-box;
        }
    }

    .flatInfo {
        padding: 0;

        .planImage {
            width: 100%;

            img {
                background-color: #fff;
            }
            
        }

        .flatInfoHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 18px;
            ._360 {
                display: flex;
                justify-content: space-between;
                border-radius: 29px;
                padding: 5px 12px 5px 5px;
                width: 148px;
                align-items: center;
                background: #0a2463;
                color: #fff;
                font-weight: 500;
                font-size: 14px;
                text-decoration: none;
                border: none;
                box-sizing: border-box;

                p {
                    margin: 0;
                }

                .tourIcon {
                    background: #fff;
                    margin-left: 1px;
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    padding: 6px;
                    text-align: center;
                    span {
                        height: 16px;
                        color: #0a2463;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 17px;
                        &:first-child {
                            border-bottom: 1px solid #0a2463;
                        }
                    }
                }
            }

            .flatInfoTitle {
                font-weight: 500;
                font-size: 14px;
                color: #102a43;
                line-height: 20px;
                margin-bottom: 0;
            }
        }
        .img {
            object-fit: cover;
            display: block;
            padding: 0 20px;
            width: 100%;
            box-sizing: border-box;
            margin-bottom: 18px;
        }
    }

    .detailedInfo {
        padding: 0;
        & > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 4px;
            font-size: 15px;
            span:first-child {
                color: #4a4a4a;
            }
            span:last-child {
                font-weight: 500;
            }
        }
    }

    section {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: 5px;
        box-sizing: border-box;
        padding: 15px;
        margin-bottom: 7px;
    }

    .sectionRow {
        flex-direction: row;
        align-items: center;

        p {
            margin: 0 10px 0 0;
        }
    }

    .requestBooking {
        cursor: pointer;
        width: 100%;
        border: none;
        padding: 8px 0;
        border-radius: 6px;
        color: #fff;
        background: #0a2463;
        transition: all ease 0.15s;

        &:hover {
            background: lighten(#0a2463, 5);
            transition: all ease 0.2s;
        }
    }

    .sectionNotification {
        background: transparent;
        padding: 10px 0 0 0;

        .managerItem {
            width: 100%;
            margin: 5px 0;
            box-sizing: border-box;
            background: #eee;
            border-radius: 5px;
            padding: 10px;

            p {
                margin: 0;
            }

            a {
                color: #555;
                font-weight: bold;
                font-size: 15px;
                margin-top: 2px;
            }
        }

        h3 {
            font-size: 14px;
            font-weight: 600;
            margin: 0 0 5px 0;
        }
    }
}

.fieldSection {
    width: 100%;
    margin-bottom: 10px;
}

.infoRealtor {
    width: 100%;
    border: 1px solid #e5e8ef;
    padding: 10px;
    border-radius: 7px;
    line-height: 13px;
}
