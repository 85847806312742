.header {
    align-items: center;
    background: #f0f2f5;
    border-radius: 4px;
    box-shadow: 0 3px 6px rgba(0,0,0,.14);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    padding: 10px 0;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1000;

    .left {
        margin-right: 15px;
        padding: 0 25px;
        width: 25%;
    }

    .right {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .profileInfo {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .profileInfoLeft {
                display: flex;
                flex-direction: column;

                .profileInfoLeftEmail {
                    font-size: 13px;
                    color: rgba(0,0,0,0.4);
                }
            }

            .profileLogo {
                background-color: #eee;
                border-radius: 50%;
                box-shadow: 0 0 10px rgba(0,0,0,.3);
                cursor: pointer;
                height: 50px;
                margin: 0 10px;
                width: 50px;
            }
        }

        p {
            margin: 0;
        }

        .infoCompany {
        }
        .pricePlan {
            span {
                margin: 0 5px;
                cursor: pointer;
                color: #0a2463;
            }
        }
    }

    a {
        width: auto;
        
        .logo {
            display: block;
        }
    }

    p {
        font-weight: 600;
        font-size: 18px;
        color: #0a2463;
        position: relative;
        top: -2px;
        span {
            font-weight: 500;
        }
    }
}
