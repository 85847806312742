.loader {

	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background: url('../../../Assets/concrete-texture.png');

	.formLogin {
		width: 500px;
		height: 400px;
		border-radius: 10px;
		box-sizing: border-box;
		padding: 15px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

	}

}