.wrapper {
    width: 100%;

    .infoBlock {
        width: 100%;
        min-height: 450px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .list {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 10px 0;

        .item {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0;
            justify-content: space-between;
            border: 1px solid #4079c6;
            border-radius: 3px;
            margin-bottom: 2px;
            color: #4079c6;
            cursor: pointer;
            box-shadow: 0 0 0 rgba(0, 0, 0, 0);

            transition: 0.2s all;

            &:hover {
                border: 1px solid #eee;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.175);

                transition: 0.2s all;
            }

            .section {
                width: 100%;
                padding: 10px;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                p {
                    margin: 0;
                }
            }

            .line {
                width: 1px;
                height: 41px;
                background-color: #4079c6;
                display: flex;
            }
        }
    }
}
