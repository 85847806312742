.pageWrapper {
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    padding: 0;
    border-radius: 5px;

    h1 {
        margin: 0 0 10px;
    }

    .filter {
        width: 100%;
        padding: 10px;
        margin: 0 0 10px 0;
        box-sizing: border-box;
        background: rgba(255, 255, 255, 0.55);
        backdrop-filter: blur(10px);
        position: sticky;
        top: 85px;
        border-radius: 4px;
        z-index: 120;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);

        h5 {
            margin: 0 0 5px;
        }

        p {
            margin: 0 0 4px 0;
            font-size: 20px;
            color: #202020;
        }

        .form {
            width: 100%;
            display: flex;
            flex-direction: row;
            gap: 10px;

            .select {
                min-width: 230px;
            }
        }
    }
}

.listBlock {
    width: 100%;
    position: relative;

    .pagination {
        width: 100%;
        margin: 15px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.previewWidget {
    width: 100%;
    min-height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f3f3f3;
    margin: 0 0 20px;
    box-shadow: 0 0 20px rgba(0,0,0,0.2);
    border-radius: 10px;
    padding: 15px 0;
    box-sizing: border-box;

    span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 150px;
        min-height: 150px;
        background: #4079c6;
        cursor: pointer;
        border-radius: 5px;
        box-sizing: border-box;
        padding: 5px;

        .addWidgetIcon {
            color: #fff;
        }

        img {
            width: 100%;
            height: auto;
            margin-bottom: 5px;
        }
    }

    .item_plan_block {
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        margin: 0 5px 5px 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: #fff;
        overflow: hidden;
        width: 200px;

        .imagePlan {
            width: 100%;
            height: 225px;
            margin: 5px 0;
        }

        .item_head {
            background-color: #4079c6;
            border-radius: 4px;
            margin-bottom: 5px;
            padding: 5px 10px;
            color: #fff;
        }

        .item_price {
            margin: 0;
            color: #0a2463;
            font-weight: bold;
            font-size: 12px;
        }

        .item_price_2 {
            margin: 0;
            color: #0a2463;
            font-weight: bold;
            font-size: 9px;
        }
    }
}

.infoWidgetIcon {
    cursor: pointer;
    position: relative;
    top: 1px;
    left: 6px;
}

.contentPopover {
    max-width: 500px;
    max-height: 400px;
    overflow-x: auto;

    h5 {
        word-break: break-all;
        color: rgba(0,0,0,0.4);
        cursor: pointer;
        display: block;
        padding: 5px 0;

        transition: .3s all;

        &:hover {
            color: rgba(0,0,0,0.99);

            transition: .3s all;  
        }

        a {
            opacity: 0.66;

            transition: .3s all;
        }

        &:hover a {
            opacity: 1;

            transition: .3s all;  
        }
    }
}

.realestateContent {
    width: 100%;

    .realestateHeader {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .realestateBody {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 15px 0;

        .item {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            background-color: #f3f3f3;
            border-radius: 5px;
            cursor: pointer;

            .right {
                padding: 10px;
                box-sizing: border-box;
            }

            .left {
                display: flex;
                width: 100%;
                flex-direction: row;
                align-items: center;

                .image {
                    width: 60px;
                    height: 60px;
                    border-radius: 5px;
                    margin-right: 15px;
                }

                h5 {
                    font-size: 14px;
                }
            }
        }
    }
}

.searchResultBlockForm {

    width: 100%;
    max-height: 500px;
    overflow-y: auto;
    margin-top: 15px;

    .listRealestate {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 10px;
        padding: 20px 0;
    
        .item {
            width: 100%;
            border-radius: 5px;
            box-shadow: 0 0 14px rgba(0,0,0,0);
            opacity: .65;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            font-size: 15px;
            color: rgba(0,0,0,0.57);
            position: relative;
    
            transition: .3s all;
    
            &:hover {
                box-shadow: 0 0 14px rgba(0,0,0,0.33);
                opacity: 1;
    
                transition: .3s all;
            }
    
            h5 {
    
            }
    
            .image {
                border-radius: 5px;
                width: 100%;
                height: 150px;
            }

            .countFlats {
                position: absolute;
                z-index: 100;
                top: 10px;
                left: 10px;
                background-color: #fff;
                border-radius: 5px;
                padding: 1px 5px;
            }
        }
    }

}

.lineBlack {
    width: 100%;
    margin: 15px 0;
    height: 2px;
    background: rgba(0, 0, 0, 0.35);
}

.groupForm {
    width: 100%;

    h3 {
        margin: 5px 0 15px 0;
        font-size: 17px;
    }
}

.modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 999999;
    background: rgba(0, 0, 0, 0.81);
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: 0.2s all;

    .modal_content {
        width: 800px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        background: #fff;
        box-sizing: border-box;
        padding: 20px;
        border-radius: 12px;

        h3 {
            color: #333;
            font-size: 22px;
            margin: 10px 0;
        }

        .list_search_plans {
            width: 100%;
            display: flex;

            h5 {
                margin: 20px 0;
                color: #333;
            }
        }

        select {
            width: 100%;
            font-size: 18px;
            height: 35px;
            border-radius: 4px;
        }

        .plan_block {
            width: 23%;
            height: 200px;
            margin: 10px 1%;
            cursor: pointer;
        }

        .blockHeadCreateWidget {
            display: flex;
            width: 100%;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        .blockHeadCreateWidget svg {
            color: #333;
        }

        .blockAddPlanAndImageWidget {
            width: 100%;
            padding: 15px;
            box-sizing: border-box;
            margin: 15px 0;
            border: 1px dashed #fff;
            border-radius: 4px;
            align-items: center;
            position: relative;
            background: rgb(64, 121, 198);
            cursor: pointer;

            h5 {
                color: #fff;
                margin: 0;
            }
        }
    }
}

.modal_open {
    visibility: visible;
    opacity: 1;

    transition: 0.2s all;
}

.inputs_block {
    width: 100%;

    input {
        margin-bottom: 5px;
    }
}

.formItem {
    margin-bottom: 5px;
    font-size: 16px;

    &:hover {
        font-size: 16px;
    }
}

.save_widget_block {
    width: 100%;
    display: flex;
}

.code_preview {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 7px;
    background: #303030;
    position: relative;
    margin: 10px 0;

    h2 {
        margin: 5px 0 15px 0;
        font-size: 10px;
        color: #fff;
    }

    h5 {
        color: #cdca68;
    }

    .deleteWidget {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        color: red;
    }

    .code_preview_section {
        width: 100%;

        code {
            color: #fff;
            margin-bottom: 10px;
            display: block;
            background: #6e794d;
            padding: 3px 10px;
            font-weight: bold;
        }
    }
}

.widgetsBlock {
    width: 100%;

    h4 {
        margin: 15px 0 0 0;
    }
}

.listMainWgs {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;

    .mainItemWrapper {
        width: 100%;
        box-shadow: rgba(115, 82, 199, 0.28) 0 0 20px;
        padding: 10px;
        border-radius: 7px;
        display: grid;
        transition: 0.3s all;
    }

    .mainItem {
        width: 100%;
        grid-gap: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        transition: 0.3s all;

        .section {
            width: 100%;
            display: grid;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            p {
                margin: 0;
                font-weight: bold;
            }

            span {
                color: rgba(0, 0, 0, 0.35);
            }

            .photo {
                width: 45px;
                height: 45px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid rgba(0,0,0,0.2);
                font-weight: bold;
                color: rgba(0,0,0,0.3);
                box-shadow: 0 0 10px rgba(0,0,0,0.2);

                img {
                    width: 100%;
                    height: 100%;
                    max-height: 100%;
                    max-width: 100%;
                }

                .antPhoto {
                    transform: scale(3.5);
                }
            }
        }

        .group {
            display: flex;
            flex-direction: row;
            grid-gap: 10px;
            align-items: center;
            width: fit-content;

            .section {
                width: 100%;
                display: grid;
                align-items: center;
                flex-direction: column;

                p {
                    margin: 0;
                    font-weight: bold;
                }

                span {
                    color: rgba(0, 0, 0, 0.35);
                }

                .photo {
                    width: 45px;
                    height: 45px;
                    overflow: hidden;
                    border-radius: 50%;

                    img {
                        width: 100%;
                        height: 100%;
                        max-height: 100%;
                        max-width: 100%;
                    }

                    .antPhoto {
                        transform: scale(3.5);
                    }
                }
            }
        }

        .sectionRow {
            display: flex;
            flex-direction: row;
        }

        span.typeWidget {
            padding: 5px 10px;
            background-color: #4079c6;
            color: #fff;
            font-size: 7px;
            text-transform: uppercase;
            border-radius: 3px;
            font-weight: bold;
            letter-spacing: 2px;
        }
    }

    .mainItemClosed {
        height: 0;
        opacity: 0;
        padding: 0;
        overflow: hidden;
        margin-top: 0;
        border-top: 0 solid rgba(0, 0, 0, 0);

        transition: 0.4s all;
    }

    .mainItemOpened {
        height: auto;
        opacity: 1;
        padding: 10px;
        padding-bottom: 0;
        margin-top: 10px;
        border-top: 1px solid rgba(0, 0, 0, 0.19);
        overflow: visible;

        transition: 0.4s all;
    }

    .flat_item_ {
        width: 100%;
        height: 45px;
        margin: 2px 0;
        border-radius: 5px;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        display: flex;
        box-sizing: border-box;
        padding: 0 15px;
        background: #f0f2f5;

        .flat_item_control {
            display: flex;
            flex-direction: row;
            align-items: center;

            a {
                margin: 0 10px;
                position: relative;
                top: 1px;
            }
        }

        h5 {
            margin: 0px;
            color: rgba(0, 0, 0, 0.4);
            font-size: 11px;
        }
    }
}

.list_plans {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 10px;

    span {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 150px;
        background: #4079c6;
        cursor: pointer;
        border-radius: 5px;
        box-sizing: border-box;
        padding: 5px;

        .addWidgetIcon {
            color: #fff;
        }

        img {
            width: 100%;
            height: auto;
            margin-bottom: 5px;
        }
    }

    .item_plan_block {
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        margin: 0 5px 5px 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: #fff;
        overflow: hidden;

        .imagePlan {
            width: 100%;
            height: 125px;
            margin: 5px 0;
        }

        .item_head {
            background-color: #4079c6;
            border-radius: 4px;
            margin-bottom: 5px;
            color: #fff;
        }

        .item_price {
            margin: 0;
            color: #0a2463;
            font-weight: bold;
            font-size: 12px;
        }

        .item_price_2 {
            margin: 0;
            color: #0a2463;
            font-weight: bold;
            font-size: 9px;
        }
    }
}

.selectInput {
    width: 100%;
    margin: 10px 0 0 0;
}

.widgetGroupFlats {
    width: 100%;

    .header {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .content {
        width: 100%;

        .item {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            background-color: #f3f3f3;
            margin: 9px 0;
            border-radius: 5px;
            overflow: hidden;

            .left {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;

                .image {
                    width: 60px;
                    height: 60px;
                    margin-right: 15px;
                    box-shadow: 0 0 15px rgba(0,0,0,0.12);
                }
            }

            .right {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
                padding: 0 10px;
            }
        }
    }
}

.frameTour {
    width: 100%;
    height: 80vh;
    border: none;
}

.blockOptions {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 7px;
    margin: 0;

    .blockOptionsHead {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        cursor: pointer;

        h6 {
            margin: 0;
        }
    }

    .optionFullwidth {
        width: 100%;

        h5 {
            margin: 0 0 5px 0;
        }
    }

    .blockContent {
        width: 99%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 3px;
        margin: 0.5% 0;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
        box-sizing: border-box;
        border: 1px dashed rgba(0, 0, 0, 0.12);
        border-radius: 5px;

        .itemOption {
            width: 100%;
            display: flex;
            flex-direction: column;
            background: #fff;
            padding: 10px;
            border-radius: 5px;
            box-shadow: 0 0 15px #f2f2f2;
            justify-content: center;

            transition: 1.3s all;

            &:hover {
                z-index: 22;
                box-shadow: 0 0 15px #b6b6b6;
                transition: 0.3s all;
            }

            span {
                line-height: 14px;
            }

            p {
                margin: 0;
            }
        }
    }
}

.contentHead {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.blockGroup360 {
    width: 100%;
    position: relative;

    .lineExampleHeight {
        width: 6px;
        position: absolute;
        top: -8px;
        left: -9px;
        background: #0a2463;
        opacity: 0;

        transition: 0.3s all;
    }

    .lineExampleHeightOpened {
        opacity: 1;

        transition: 0.3s all;
    }
}

.formwidgetContent {
    width: 100%;
    border: 1px dashed rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    margin: 0 0 15px 0;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
}

.buttonSave {
    width: fit-content;
    margin: 15px 0 0 0;
}

.secondText {
    margin: 10px 0;
    font-size: 15px;
    text-decoration: underline;
}

.tag {
    text-align: center;
    height: fit-content;
}

.blockPayments {
    width: 100%;

    .loading {
        width: 100%;
        min-height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .listPayments {
        width: 100%;
        display: flex;
        flex-direction: column;

        .listPaymentsHead {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .listPaymentsContent {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 10px 0;

            .itemFooter {
                background-color: rgba(0,0,0,0.2);
                padding: 10px;
                box-sizing: border-box;
            }

            .item {
                width: 100%;
                display: flex;
                flex-direction: row;
                padding: 10px;
                border: 1px solid rgba(0,0,0,0.12);
                border-radius: 5px;
                gap: 10px;
                cursor: pointer;

                .left {
                    width: 100%;
                    display: grid;
                    gap: 10px;
                    grid-template-columns: 190px 110px 110px 155px 110px 50px;
                    align-items: center;

                    .text {
                        display: block;
                        width: 100px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                .right {
                    display: flex;
                    flex-direction: row;
                    gap: 3px;
                }
            }
        }
    }
}

.inputWithoutMargin {
    margin: 0;
}

.blockPhones {
    width: 100%;
    padding: 10px;
    background-color: #ebebeb;
    border: 1px solid #dbdbdb;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 10px;

    .messages {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .phoneItem {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .item {
            width: 100%;
            margin: 0;
        }
    }
}

.calcItem {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    background-color: rgba(0,0,0,0.055);
    margin: 7px 0;
    box-sizing: border-box;

    .calcItemTop {
        width: fit-content;

        h4 {
            font-weight: bold;
            font-size: 17px;
            margin: 0;
        }

        .apartmentItem {
            width: 100%;
            background-color: #e1e1e1;
            margin: 5px 0;
            box-sizing: border-box;
            padding: 10px;
            border-radius: 5px;

            b {
                line-height: 28px;
            }
        }
    }
}

.selectTypeInput {
    width: 100%;
}

.standartInput {
    width: 100%;
    margin-top: 10px;
}

.formPayment {
    width: 100%;
    margin: 10px 0;

    .item {
        margin: 0;

    }

    .conditions {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        border: 1px dashed rgba(0,0,0,0.2);
        border-radius: 5px;
        margin-bottom: 10px;
        margin-top: 10px;

        .conditionItem {
            width: 100%;
            background: #f5f5f5;
            border: 1.88px dashed #272727;
            box-sizing: border-box;
            padding:  10px;
            margin-bottom: 15px;
            border-radius: 5px;
            transition: .2s all;

            &:hover {
                background: #fffdf5;
                box-shadow: 0 0 20px rgba(0,0,0,0.22);
                border: 1.88px dashed #e2e2e2;
                
                transition: .2s all;
            }

            .conditionApartmentsWraper {
                width: 100%;
                padding: 10px;
                box-sizing: border-box;
                background-color: #e9e9e9;
                border-radius: 5px;

                .conditionApartments {
                    width: 100%;
    
                    .conditionApartment {
                        width: 100%;
                        display: flex;
                        flex-direction: row; 
                        gap: 10px;
                        margin-bottom: 10px;
                        background-color: #fff;
                        padding: 10px;
                        border-radius: 5px;
                        box-sizing: border-box;

                        .infoBlock {
                            display: flex;
                            flex-direction: column;

                            .sort {
                                display: flex;
                                flex-direction: column;
                            }

                        }
    
                        .rooms {
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                            background-color: #ebe7ff;
                            padding: 7px 10px;
                            box-sizing: border-box;
                            border-radius: 5px;
                            border: 1px solid #d3d3d3;
                            margin-bottom: 10px;

                            .main {
                                display: flex;
                                flex-direction: row;
                                gap: 10px;
                            }
                        }
    
                        .extra {
                            display: flex;
                            flex-direction: row;
                            gap: 10px;
                            background-color: #f3ffed;
                            padding: 7px 10px;
                            box-sizing: border-box;
                            border-radius: 5px;
                            border: 1px solid #d3d3d3;
                            height: fit-content;
                        }
                    }
    
                }
            }

            .condition {
                width: 100%;
                display: flex;
                flex-direction: row;
                gap: 10px;
                margin-bottom: 5px;

                .item {
                    width: 100%;
                }
    
                .deleteConditionIcon {
                    position: relative;
                    top: 7px;
                }
    
            }
        }
    }
}

.itemData {
    width: 100%;
    gap: 10px;
    box-sizing: border-box;

    .item {
        display: flex;
        flex-direction: row;
        float: left;
        margin: 0 5px 5px 0;
        gap: 0px;
        padding: 8px 5px;
        background-color: #eee;
        box-sizing: border-box;
        border-radius: 5px;
    }
}

.blockSearch {
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    padding: 0px 15px;
    border-radius: 5px;

    .searchResultBlock {
        width: 100%;
        margin: 0;

        .searchResultContent {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 10px;

            > span {
                width: 100%;
                text-align: center;
            }

            .item {
                cursor: pointer;
            }
        }
    }
}

.inputItem {
    margin: 0 0 7px 0;
}

.flexBlock {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 10px 0;
}