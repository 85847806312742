.settingsWrapper {
    background: #ffffff;
    border-radius: 10px;
    .settingsTitle {
        color: #0a2463 !important;
        font-weight: 300 !important;
        font-size: 20px !important;
    }

    .settingLinks {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
        background: #eee;
        border-radius: 4px;

        a {
            display: block;
            padding: 10px;
            border-radius: 4px;
            text-decoration: none;
            color: #0a2463;
            transition: 0.4s all;
        }

        .activeLink {
            background: #0a2463;
            color: #fff;

            transition: 0.4s all;
        }
    }

    .blockSections {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 5px;

        .form {
            width: 100%;

            .input {
                margin: 0;

                label {
                    position: relative;
                    top: 8px;
                    height: 20px;
                }
            }

        }
        

        .blockSection {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            box-sizing: border-box;
            padding: 10px 15px;
            margin: 0;
            color: #67738f;

            &:first-child {
                border-radius: 5px 5px 0 0;
            }
            &:last-child {
                border-radius: 0 0 5px 5px;
            }

            &:nth-child(odd) {
                background: #eef4ff;
            }
            &:nth-child(even) {
                background: #d6e3f4;
            }

            .nameInfo {
                display: flex;
                width: 160px;
                font-weight: 500;
                font-size: 15px;
            }

            .userInfo {
                font-size: 15px;
                font-weight: 500;
            }
        }
    }
}

.mortgageForm {
    width: 100%;
  }
  
.formContent {
width: 100%;
display: flex;
flex-direction: column;
background-color: #ededed;
padding: 10px;
box-sizing: border-box;
border-radius: 10px;

    .item {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;

        .itemForm {
            width: 100%;
            margin: 0 0 5px 0;
        }

        .iconRemove {
            position: relative;
            top: 6px;
        }
    }
}

.button {
    width: 100%;
    margin-top: 10px;
}

.blockLoading {
    width: 100%;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.itemForm {
    margin-bottom: 5px;
}

.listMortgage {
    width: 100%;
    display: flex;
    flex-direction: column;

    .listMortgageHeader {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-bottom: 10px;
    }
}

.listItemsList {
    width: 100%;
    margin: 15px 0;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 7px;

    .listItemsListWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 7px;

        .item {
            display: flex;
            width: 100%;
            flex-direction: row;
            gap: 10px;
            align-items: center;

            .itemInput {
                width: 100%;
                margin: 0;
            }
        }
    }
    
}

.wrapperCentered {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 7px;
}

.wrapperNotes {
    width: 100%;

    .header {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}