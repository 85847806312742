.wrapper {
    width: 100%;

    .title {
        color: #0a2463 !important;
        font-weight: 300 !important;
        font-size: 20px !important;
    }
}

.frameBlockPDF {
    width: 100%;
    height: 98%;
    border: none;
    outline: none;
}

.contractTemplateWrapper {
    width: 100%;

    .header {
        width: 100%;
        margin: 10px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .content {
        width: 100%;
    }
}

.formTemplate {
    width: 100%;

    .formItem {
        margin:  0 0 5px 0;
    }
}

.timerBlock {
    display: flex;
    flex-direction: row;
    gap: 1px;

    span {
        display: flex;
        color: #eee;
        background-color: #333;
        border-radius: 4px;
        font-size: 15px;
        letter-spacing: 2px;
        padding: 1px 7px;
        width: 33px;
        align-items: center;
        justify-content: center;
    }

    p {
        margin: 0;
        padding: 0;
        font-weight: bold;
        font-size: 16px;
    }
}