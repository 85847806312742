.managerWrapper {
    width: 100%;

    .headManagerPage {
        display: flex;
        justify-content: space-between;
        margin-bottom: 18px;
        .title {
            color: #0a2463;
            font-weight: 500;
            font-size: 1.25em;
        }
        .createManager {
            cursor: pointer;
            padding: 10px;
            background: #5ebf15;
            border: none;
            color: #fff;
            font-size: 0.875em;
            border-radius: 7px;
            transition: all ease 0.15s;

            &:hover {
                background: darken(#5ebf15, 2);
                transition: all ease 0.2s;
            }
        }
    }

    .managersGrid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-gap: 10px;
    }

    .managersNotFound {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 300px;
        color: rgba(#000, 0.45);
        font-weight: 500;
        width: 100%;
    }
}
