.content {
    width: 100%;
    max-width: 70vw;
    margin: 0 auto;
    min-height: 95vh;
    box-sizing: border-box;
    border-radius: 7px;
    position: relative;

    @media screen and (max-width: 1920px) {
        max-width: 98vw;
    }

    h2 {
        color: #4079c6;
        font-weight: 300;
        margin: 0 0 9px 0;
    }

    .loading {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .sections {
        width: 100%;
        margin-top: 15px;
        display: flex;
        flex-direction: row;

        .sectionWrapperLeft {
            width: 25%;
            min-width: 20%;
            margin-right: 15px;

            .sectionLeft {
                background-color: #586ce9;
                border-radius: 4px;
                height: fit-content;
                padding: 0px;
                top: 85px;
                width: 100%;
    
                .openFlatSidebar {
                    cursor: pointer;
                    background: #0a2463;
                    color: #fff;
                    padding: 6px 0;
                    border: none;
                    border-radius: 5px;
                    font-size: 16px;
                    width: 100%;
                    margin-bottom: 18px;
                }
    
                .bookingNotification {
                    width: 100%;
                    background-color: #f2f4f6;
                    position: sticky;
                    top: 0;
                    z-index: 1111111;
                    max-height: 100vh;
                    overflow-y: auto;
                    box-sizing: border-box;
                    padding: 20px 0;
                }
            }
        }
        .sectionRight {
            width: 100%;
            max-width: 75%;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 0 0 10px rgba(0,0,0,.2);
            padding: 15px;
            margin-bottom: 15px;
        }
    }
}

.salesReportWrapper {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    background-color: #eee;
    border-radius: 4px;
    margin-top: 10px;
    margin-bottom: 10px;

    h5 {
        color: #000;
        margin: 0;
        color: #a7a7a7;
    }

    fieldset {
        border: 1px solid #d0d0d0;
        border-radius: 5px;
        padding: 3px 10px;
        margin: 5px 0;

        .section {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            white-space: nowrap;
            align-items: flex-end;
            margin-bottom: 7px;

            p {
                margin: 0;
                color: #bbbbbb;
            }

            .line {
                width: 100%;
                border-bottom: 1px dotted #cfcfcf;
                margin: 0 5px;
            }

            h3 {
                color: #000000;

                span {
                    color: #acacac;
                }
            }

        }
    }
    
    legend {
        font-size: 14px;
        font-weight: bold;
        color: #9b9b9b;
    }
}
