.pageNoFound {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background: url('../../../Assets/skulls.png');
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 10px;

	h1 {
		font-weight: 300;
		font-size: 22px;
	}

	h4 {
		font-weight: 700;
		font-size: 77px;
	}

	span {
		display: flex;
	    padding: 10px 20px;
	    background: #fff;
	    cursor: pointer;
	    border-radius: 5px;
	    margin: 20px 0;
	    box-shadow: 0 0 25px rgba(0,0,0,.2);
	}
}